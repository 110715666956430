import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#f8f8f8', p: 2, mt: 'auto', textAlign: 'center' }}>
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} Kirupam (Pvt) Ltd. All rights reserved. || Developed by Lemda (Pvt) Ltd
      </Typography>
    </Box>
  );
};

export default Footer;
