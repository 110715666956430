import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const BottomNav = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        switch (newValue) {
          case 0:
            navigate('/dashboard');
            break;
          case 1:
            navigate('/assigned-orders');
            break;
          case 2:
            navigate('/task-management');
            break;
          case 3:
            navigate('/earnings');
            break;
          default:
            navigate('/dashboard');
        }
      }}
      sx={{ 
        width: '100%', 
        position: 'fixed', 
        bottom: 0,
        bgcolor: '#E6E6FA' // light purple background
      }}
    >
      <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
      <BottomNavigationAction label="Orders" icon={<ListAltIcon />} />
      <BottomNavigationAction label="Tasks" icon={<AssignmentIcon />} />
      <BottomNavigationAction label="Earnings" icon={<AssignmentIcon />} />
    </BottomNavigation>
  );
};

export default BottomNav;
