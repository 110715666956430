import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Import icon for assigned orders

const drawerWidth = 240;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <List sx={{ mt: 8 }}>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {/* <ListItem button component={Link} to="/orders">
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem> */}
          <ListItem button component={Link} to="/assigned-orders">
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Assigned Orders" />
          </ListItem>
          <ListItem button component={Link} to="/task-management">
            <ListItemIcon><DirectionsBikeIcon /></ListItemIcon>
            <ListItemText primary="Task" />
          </ListItem>
        </List>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <List sx={{ mt: 8 }}>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {/* <ListItem button component={Link} to="/orders">
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem> */}
          <ListItem button component={Link} to="/assigned-orders">
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Assigned Orders" />
          </ListItem>
          <ListItem button component={Link} to="/task-management">
            <ListItemIcon><DirectionsBikeIcon /></ListItemIcon>
            <ListItemText primary="Task" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
