import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = ({ handleDrawerToggle, onLogout }) => {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <MenuIcon onClick={handleDrawerToggle} sx={{ cursor: 'pointer', marginRight: 2 }} />
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          Rider Management
        </Typography>
        <Button color="inherit" onClick={onLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
