import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button, List, ListItem, ListItemText, CircularProgress, Divider, IconButton } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const OrderDetails = () => {
  const routeLocation = useLocation(); // Renamed to avoid conflict
  const orderId = routeLocation.state?.order?.id;
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null); // Renamed to avoid conflict

  useEffect(() => {
    fetchOrderDetails();
    fetchCurrentLocation();
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/get_order_details.php?order_id=${orderId}`);
      setOrderDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order details:', error);
      setLoading(false);
    }
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, '_blank');
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const handleOrderStatusChange = async (status) => {
    try {
      await axios.post('https://kirupam.shop/admin/admin_api/update_order_status.php', {
        order_id: orderId,
        status
      });
      fetchOrderDetails(); // Refresh order details
    } catch (error) {
      console.error(`Error updating order status to ${status}:`, error);
    }
  };

  if (loading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
  }

  const parsedCartItems = orderDetails?.cart_items ? JSON.parse(orderDetails.cart_items) : [];

  return (
    <Box sx={{ mt: 3, p: 2 }}>
      {orderDetails ? (
        <Card sx={{ mb: 2, borderRadius: 4, boxShadow: 3, p: 2 }}>
          <CardContent>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#4caf50' }}>Order Details</Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Order ID: {orderDetails.id}</Typography>
              <Typography variant="subtitle1"><strong>Customer:</strong> {orderDetails.customer_name}</Typography>
              <Typography variant="subtitle1">
                <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {orderDetails.customer_phone}
              </Typography>
              <Typography variant="subtitle1">
                <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {orderDetails.customer_email}
              </Typography>
              <Typography variant="subtitle1"><strong>Delivery Fee:</strong> {orderDetails.delivery_charge} LKR</Typography>
              <Typography variant="subtitle1">
                <StorefrontIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {orderDetails.store_name}
              </Typography>
              <Typography variant="subtitle1">
                <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {orderDetails.store_phone}
              </Typography>
              <Typography variant="subtitle1">
                <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {orderDetails.store_address}
              </Typography>
              <Typography variant="subtitle1"><strong>Order Date:</strong> {orderDetails.selected_date}</Typography>
              <Typography variant="subtitle1"><strong>Status:</strong> {orderDetails.order_status}</Typography>
              <Typography variant="subtitle1"><strong>Total:</strong> {orderDetails.totalCharge} LKR</Typography>
            </Box>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              sx={{ mb: 2, backgroundColor: '#4caf50' }} 
              onClick={() => openGoogleMaps(orderDetails.store_location_lat, orderDetails.store_location_lng)}
              startIcon={<StorefrontIcon />}
            >
              Navigate to Store
            </Button>
            {orderDetails.order_status === 'picked' && (
              <Button 
                variant="contained" 
                color="secondary" 
                fullWidth 
                sx={{ mb: 2, backgroundColor: '#ff9800' }} 
                onClick={() => openGoogleMaps(orderDetails.delivery_location_lat, orderDetails.delivery_location_lng)}
                startIcon={<DeliveryDiningIcon />}
              >
                Navigate to Delivery
              </Button>
            )}
            {currentLocation && (
              <>
                {orderDetails.order_status === 'asigned' && calculateDistance(currentLocation.latitude, currentLocation.longitude, orderDetails.store_location_lat, orderDetails.store_location_lng) <= 100 && (
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    fullWidth 
                    sx={{ mb: 2, backgroundColor: '#ff9800' }} 
                    onClick={() => handleOrderStatusChange('picked')}
                    startIcon={<DeliveryDiningIcon />}
                  >
                    Picked
                  </Button>
                )}
                {orderDetails.order_status === 'picked' && calculateDistance(currentLocation.latitude, currentLocation.longitude, orderDetails.delivery_location_lat, orderDetails.delivery_location_lng) <= 100 && (
                  <Button 
                    variant="contained" 
                    color="success" 
                    fullWidth 
                    sx={{ mb: 2, backgroundColor: '#4caf50' }} 
                    onClick={() => handleOrderStatusChange('delivered')}
                    startIcon={<DeliveryDiningIcon />}
                  >
                    Delivered
                  </Button>
                )}
              </>
            )}
            <Typography variant="h5" sx={{ mt: 2, textAlign: 'center', color: '#4caf50' }}>Order Items</Typography>
            <List>
              {parsedCartItems.map((item, index) => (
                <ListItem key={index} sx={{ display: 'block', backgroundColor: '#f1f1f1', mb: 1, borderRadius: 2, p: 2 }}>
                  <ListItemText
                    primary={<Typography variant="h6">{item.food}</Typography>}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="text.primary">
                          Category: {item.category}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.primary">
                          Restaurant: {orderDetails.store_name}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.primary">
                          Size: {item.selectedSizes.map(size => size.size).join(', ')}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.primary">
                          Quantity: {item.selectedSizes.map(size => size.quantity).join(', ')}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.primary">
                          Custom Options: {Object.values(item.selectedCustomOptions).flat().join(', ')}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="text.primary">
                          Total Price: {item.totalPrice}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6">No order details available.</Typography>
      )}
    </Box>
  );
};

export default OrderDetails;
