import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Orders from './components/Orders';
import AssignedOrders from './components/AssignedOrders';
import Login from './components/Login';
import Task from './components/Tasks';
import { CssBaseline, Box, Toolbar, Alert, Snackbar, useMediaQuery } from '@mui/material';
import Register from './components/Register';
import OrderDetails from './components/OrderDetails';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
import Earnings from './components/Earnings';
import axios from 'axios';

const drawerWidth = 240;

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOfflineAlert, setShowOfflineAlert] = useState(false);
  const [showOnlineAlert, setShowOnlineAlert] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogin = (type) => {
    setLoggedIn(true);
    setUserType(type);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUserType('');
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowOnlineAlert(true);
      setTimeout(() => setShowOnlineAlert(false), 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowOfflineAlert(true);
      setTimeout(() => setShowOfflineAlert(false), 3000);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const riderId = sessionStorage.getItem('riderId');

    const updateLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            try {
              await axios.post('https://kirupam.shop/admin/admin_api/update_rider_location.php', {
                rider_id: riderId,
                latitude,
                longitude
              });
            } catch (error) {
              console.error('Error updating location:', error);
            }
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    const fetchAssignedOrders = async () => {
      try {
        const response = await axios.get(`https://kirupam.shop/admin/admin_api/get_assigned_orders.php?rider_id=${riderId}`);
        const orders = response.data.filter(order => order.order_status !== 'delivered');
        if (orders.length > 0) {
          setNewOrders(orders);
        }
      } catch (error) {
        console.error('Error fetching assigned orders:', error);
      }
    };

    const locationIntervalId = setInterval(updateLocation, 10000);
    const ordersIntervalId = setInterval(fetchAssignedOrders, 3000);

    return () => {
      clearInterval(locationIntervalId);
      clearInterval(ordersIntervalId);
    };
  }, [loggedIn]);

  if (!loggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar handleDrawerToggle={handleDrawerToggle} onLogout={handleLogout} />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
              }}
            >
              <Toolbar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/task-management" element={<Task />} />
                <Route path="/register" element={<Register />} />
                <Route path="/assigned-orders" element={<AssignedOrders />} />
                <Route path="/order-details/:id" element={<OrderDetails />} /> {/* New route for order details */}
                <Route path="/earnings" element={<Earnings />} />
              </Routes>
            </Box>
          </Box>
          <Footer />
        </Box>
        {isMobile && <BottomNav />}
      </Router>

      <Snackbar 
        open={showOfflineAlert} 
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          You are offline. Please check your internet connection.
        </Alert>
      </Snackbar>
      <Snackbar 
        open={showOnlineAlert} 
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Internet connection restored.
        </Alert>
      </Snackbar>
      {newOrders.map((order) => (
        <Snackbar
          key={order.id}
          open={true}
          autoHideDuration={6000}
          onClose={() => setNewOrders((prevOrders) => prevOrders.filter((o) => o.id !== order.id))}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info" sx={{ width: '100%' }}>
            New Order Assigned: Order ID: {order.id}, Total: {order.totalCharge}
          </Alert>
        </Snackbar>
      ))}
    </ThemeProvider>
  );
};

export default App;
