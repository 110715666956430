import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, CircularProgress, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemText, Pagination } from '@mui/material';
import useSound from 'use-sound';
import notificationSound from './success.mp3';

const AssignedOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const riderId = sessionStorage.getItem('riderId');
  const [playNotificationSound] = useSound(notificationSound);

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (location) {
      fetchOrders();
    }
  }, [location, page]);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/orders.php?rider_id=${riderId}&latitude=${location.latitude}&longitude=${location.longitude}`);
      if (Array.isArray(response.data)) {
        const assignedOrders = response.data.filter(order => order.asigned_delivery_rider == riderId);
        setOrders(assignedOrders);
        checkForNewOrders(assignedOrders);
      } else {
        console.error('Invalid response format:', response.data);
        setOrders([]);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkForNewOrders = (orders) => {
    if (Array.isArray(orders) && orders.length > 0) {
      setNotification({ open: true, message: 'New assigned order!' });
      playNotificationSound();
    }
  };

  const handleCardClick = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ mt: 3 }}>
      {loading ? <CircularProgress /> : (
        <>
          {orders.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((order) => (
            <Card key={order.id} sx={{ mb: 2 }} onClick={() => handleCardClick(order)} style={{ cursor: 'pointer' }}>
              <CardContent>
                <Typography variant="h6">Order ID: {order.id}</Typography>
                <Typography>Customer: {order.customer_name}</Typography>
                <Typography>Delivery Fee: {order.delivery_charge} LKR</Typography>
                <Typography>Delivery Fee: {order.store_name}</Typography>
                <Typography>Order Date: {order.order_date}</Typography>
                <Typography>Status: {order.status}</Typography>
                <Typography>Total: {order.total} LKR</Typography>
                <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); openGoogleMaps(order.store_location_lat, order.store_location_lng); }}>
                  Navigate to Store
                </Button>
                {order.status.toLowerCase() === 'picked' && (
                  <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); openGoogleMaps(order.delivery_latitude, order.delivery_longitude); }}>
                    Navigate to Delivery
                  </Button>
                )}
                {order.status.toLowerCase() === 'delivered' && (
                  <Button variant="contained" color="success" onClick={(e) => { e.stopPropagation(); alert('Please rate us!'); }}>
                    Rate Us
                  </Button>
                )}
              </CardContent>
            </Card>
          ))}
          <Pagination
            count={Math.ceil(orders.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
          />
        </>
      )}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert onClose={() => setNotification({ ...notification, open: false })} severity="info">
          {notification.message}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <DialogContentText>ID: {selectedOrder.id}</DialogContentText>
              <DialogContentText>Customer: {selectedOrder.customer_name}</DialogContentText>
              <DialogContentText>Order Date: {selectedOrder.order_date}</DialogContentText>
              <DialogContentText>Status: {selectedOrder.status}</DialogContentText>
              <DialogContentText>Total: {selectedOrder.total}</DialogContentText>
              <DialogContentText>Delivery Latitude: {selectedOrder.delivery_latitude}</DialogContentText>
              <DialogContentText>Delivery Longitude: {selectedOrder.delivery_longitude}</DialogContentText>
              <Typography variant="h6" sx={{ mt: 2 }}>Order Items</Typography>
              <List>
                {JSON.parse(selectedOrder.cart_items).map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={item.food}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            Category: {item.category}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Restaurant: {selectedOrder.store_name}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Size: {item.selectedSizes.map(size => size.size).join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Quantity: {item.selectedSizes.map(size => size.quantity).join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Custom Options: {Object.values(item.selectedCustomOptions).flat().join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Total Price: {item.totalPrice}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssignedOrders;
