import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, CircularProgress, List, ListItem, Divider, Grid, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/lab'; // Import DatePicker directly

const Earnings = () => {
  // State variables
  const [earnings, setEarnings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const riderId = sessionStorage.getItem('riderId');

  // Fetch earnings data on component mount
  useEffect(() => {
    fetchEarnings();
  }, []);

  // Fetch earnings data
  const fetchEarnings = async (start = null, end = null) => {
    setLoading(true);
    try {
      const params = { rider_id: riderId };
      if (start && end) {
        params.start_date = start.toISOString().split('T')[0];
        params.end_date = end.toISOString().split('T')[0];
      }
      const response = await axios.get('https://kirupam.shop/admin/admin_api/earnings.php', { params });
      console.log('API Response:', response.data); // Log API response for debugging

      // Ensure the response data is an array
      const earningsData = Array.isArray(response.data) ? response.data : [];
      setEarnings(earningsData);

      // Calculate total earnings and orders
      const totalEarnings = earningsData.reduce((sum, earning) => sum + parseFloat(earning.delivery_charge), 0);
      const totalOrders = earningsData.reduce((sum, earning) => sum + parseInt(earning.orders), 0);

      setTotalEarnings(totalEarnings);
      setTotalOrders(totalOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching earnings:', error);
      setEarnings([]); // Ensure earnings is an array in case of error
      setLoading(false);
    }
  };

  const handleFilter = () => {
    if (startDate && endDate) {
      fetchEarnings(startDate, endDate);
    }
  };

  // Loading state
  if (loading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
  }

  // Render earnings list
  return (
    <Box sx={{ mt: 3, p: 2, height: '100vh' }}>
      <Card sx={{ mb: 2, borderRadius: 4, boxShadow: 3, p: 2 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h3" color="primary">LKR {totalEarnings.toFixed(2)}</Typography>
              <Typography variant="subtitle1">Total earnings</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ ml: 2 }} />}
              />
              <Button variant="contained" color="primary" onClick={handleFilter} sx={{ ml: 2, mt: '16px' }}>
                Filter
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 4, boxShadow: 3 }}>
      <Typography variant="subtitle1" sx={{marginLeft:'10px'}}>Earnings</Typography>
        {earnings.map((earning, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{new Date(earning.selected_date).toLocaleDateString()}</Typography>
                  <Typography variant="body2">{earning.payment_method} </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="right">LKR {parseFloat(earning.delivery_charge).toFixed(2)}</Typography>
                </Grid>
              </Grid>
            </ListItem>
            {index < earnings.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default Earnings;
