import React, { useState } from 'react';
import { Box, TextField, Button, Typography, InputLabel, FormControl, styled } from '@mui/material';
import axios from 'axios';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '90%',
  maxWidth: '400px',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '50px',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper,
}));

const StyledInput = styled('input')({
  display: 'none',
});

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: '10px 0',
  textAlign: 'center',
  marginBottom: '20px',
  fontSize: '20px',
  fontWeight: 'bold',
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.secondary,
  padding: '10px 0',
  textAlign: 'center',
  marginTop: '20px',
  fontSize: '14px',
}));

const Register = ({ onRegister }) => {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    address: '',
    vehicle_number: '',
    vehicle_photo: '',
    profile_image: '',
    nic_number: '',
    licence_photo: '',
    password: '',
  });
  const [fileNames, setFileNames] = useState({
    vehicle_photo: '',
    profile_image: '',
    licence_photo: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
    setFileNames({ ...fileNames, [name]: files[0].name });
  };

  const handleRegister = async () => {
    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    form.append('approve_status', 'pending');
    form.append('active', '1');

    try {
      const response = await axios.post('https://kirupam.shop/admin/admin_api/rider_register.php', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        onRegister();
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration error');
    }
  };

  return (
    <>
      <Header>
        Kirupam Delivery Partner
      </Header>
      <StyledBox>
        <Typography variant="h5" gutterBottom>Rider Registration</Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            label="Address"
            fullWidth
            margin="normal"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            label="Vehicle Number"
            fullWidth
            margin="normal"
            name="vehicle_number"
            value={formData.vehicle_number}
            onChange={handleInputChange}
            variant="outlined"
          />
          <FormControl fullWidth margin="normal">
            <StyledInput
              accept="image/*"
              id="vehicle_photo"
              type="file"
              name="vehicle_photo"
              onChange={handleFileChange}
            />
            <label htmlFor="vehicle_photo">
              <Button variant="contained" component="span" fullWidth>
                {fileNames.vehicle_photo || 'Upload Vehicle Photo'}
              </Button>
            </label>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <StyledInput
              accept="image/*"
              id="profile_image"
              type="file"
              name="profile_image"
              onChange={handleFileChange}
            />
            <label htmlFor="profile_image">
              <Button variant="contained" component="span" fullWidth>
                {fileNames.profile_image || 'Upload Profile Image'}
              </Button>
            </label>
          </FormControl>
          <TextField
            label="NIC Number"
            fullWidth
            margin="normal"
            name="nic_number"
            value={formData.nic_number}
            onChange={handleInputChange}
            variant="outlined"
          />
          <FormControl fullWidth margin="normal">
            <StyledInput
              accept="image/*"
              id="licence_photo"
              type="file"
              name="licence_photo"
              onChange={handleFileChange}
            />
            <label htmlFor="licence_photo">
              <Button variant="contained" component="span" fullWidth>
                {fileNames.licence_photo || 'Upload License Photo'}
              </Button>
            </label>
          </FormControl>
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            sx={{ mt: 2, width: '100%', padding: '10px', fontSize: '16px' }}
          >
            Register
          </Button>
        </Box>
      </StyledBox>
      <Footer>
        Developed by Lemda (Pvt) Ltd
      </Footer>
    </>
  );
};

export default Register;
