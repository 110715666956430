import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Button, CircularProgress, Divider } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsIcon from '@mui/icons-material/Directions';

const Tasks = ({}) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const riderId = sessionStorage.getItem('riderId');
  
    useEffect(() => {
      fetchTasks();
    }, []);
  
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `https://kirupam.shop/admin/admin_api/tasks.php?rider_id=${riderId}`
        );
        setTasks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setLoading(false);
      }
    };
  
    const openGoogleMaps = (lat, lng) => {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      window.open(url, '_blank');
    };
  
    const handleAccept = async (taskId) => {
        try {
          await axios.get(`https://kirupam.shop/admin/admin_api/update_task_status.php?task_id=${taskId}&status=Accepted`);
          fetchTasks(); // Refresh tasks
        } catch (error) {
          console.error('Error updating task status:', error);
        }
      };
      
      const handleDecline = async (taskId) => {
        try {
          await axios.get(`https://kirupam.shop/admin/admin_api/update_task_status.php?task_id=${taskId}&status=Declined`);
          fetchTasks(); // Refresh tasks
        } catch (error) {
          console.error('Error updating task status:', error);
        }
      };
      
  
    const handleComplete = async (taskId) => {
      // Implement logic to check if the delivery location and rider location are within 100m distance
      // For now, assume it is within 100m
      try {
        await axios.post('https://kirupam.shop/admin/admin_api/update_task_status.php', {
          task_id: taskId,
          status: 'Completed',
        });
        fetchTasks(); // Refresh tasks
      } catch (error) {
        console.error('Error updating task status:', error);
      }
    };
  
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }
  
    return (
      <Box sx={{ mt: 3, p: 2 }}>
        {tasks.map((task) => (
          <Card key={task.id} sx={{ mb: 2, borderRadius: 4, boxShadow: 3, p: 2 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {task.title}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1">{task.description}</Typography>
              <Typography variant="subtitle1">Status: {task.status}</Typography>
              <Typography variant="subtitle1">Delivery Charge: {task.delivery_charge} LKR</Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mb: 2, backgroundColor: '#4caf50' }}
                onClick={() => openGoogleMaps(task.store_location_lat, task.store_location_lng)}
                startIcon={<LocationOnIcon />}
              >
                Navigate to Store
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mb: 2, backgroundColor: '#ff9800' }}
                onClick={() => openGoogleMaps(task.delivery_location_lat, task.delivery_location_lng)}
                startIcon={<LocationOnIcon />}
              >
                Navigate to Delivery
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => alert('View details')}
                startIcon={<DirectionsIcon />}
              >
                View Details
              </Button>
              {task.status === 'pending' && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, mb: 1, backgroundColor: '#4caf50' }}
                    onClick={() => handleAccept(task.id)}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mb: 2, backgroundColor: '#ff9800' }}
                    onClick={() => handleDecline(task.id)}
                  >
                    Decline
                  </Button>
                </>
              )}
              {task.status === 'Accepted' && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2, backgroundColor: '#4caf50' }}
                  onClick={() => handleComplete(task.id)}
                >
                  Task Completed
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };
  
  export default Tasks;
  
