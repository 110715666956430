import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';

const Login = ({ onLogin, onRegisterClick }) => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`https://kirupam.shop/admin/admin_api/rider_login.php`, {
        phone,
        password,
      });

      if (response.data.success) {
        const { id, name } = response.data.rider;
        sessionStorage.setItem('riderId', id);
        sessionStorage.setItem('riderName', name);
        onLogin('rider');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login error');
    }
  };

  return (
    <Box sx={{ width: '300px', margin: '0 auto', textAlign: 'center', marginTop: '200px' }}>
      <Typography variant="h5" gutterBottom>Rider Login</Typography>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Phone"
          fullWidth
          margin="normal"
          value={phone}
          onChange={handlePhoneChange}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{ mt: 2 }}
        >
          Login
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={onRegisterClick}
          sx={{ mt: 2 }}
        >
          Don't have an account? Register Now
        </Button>
      </Box>
      <Typography variant="body2" color="gray" align="center" sx={{ mt: 4 }}>
        Developed by Lemda (Pvt) Ltd
      </Typography>
    </Box>
  );
};

export default Login;
