import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, CircularProgress, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemText, Pagination, Divider } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const riderId = sessionStorage.getItem('riderId');

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (location) {
      fetchOrders();
    }
  }, [location, page]);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/orders.php?rider_id=${riderId}&latitude=${location.latitude}&longitude=${location.longitude}`);
      if (Array.isArray(response.data)) {
        setOrders(response.data);
      } else {
        console.error('Invalid response format:', response.data);
        setOrders([]);
      }
      checkForNewOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkForNewOrders = (orders) => {
    if (Array.isArray(orders) && orders.length > 0) {
      setNotification({ open: true, message: 'New order placed!' });
    }
  };

  const handleCardClick = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ mt: 3, p: 2 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {orders.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((order) => (
            <Card key={order.id} sx={{ mb: 2, borderRadius: 4, boxShadow: 3, p: 2 }} onClick={() => handleCardClick(order)} style={{ cursor: 'pointer' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', color: '#4caf50' }}>Order ID: {order.id}</Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="subtitle1"><strong>Customer:</strong> {order.customer_name}</Typography>
                <Typography variant="subtitle1"><strong>Delivery Fee:</strong> {order.delivery_charge} LKR</Typography>
                <Typography variant="subtitle1"><strong>Order Date:</strong> {order.order_date}</Typography>
                <Typography variant="subtitle1"><strong>Status:</strong> {order.status}</Typography>
                <Typography variant="subtitle1"><strong>Total:</strong> {order.total} LKR</Typography>
                {(order.status === 'ready' || order.status === 'Pending' || order.status === 'asigned') && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, backgroundColor: '#4caf50' }}
                    onClick={(e) => { e.stopPropagation(); openGoogleMaps(order.store_location_lat, order.store_location_lng); }}
                    startIcon={<StorefrontIcon />}
                  >
                    Navigate to Store
                  </Button>
                )}
                {order.status === 'picked' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mt: 2, backgroundColor: '#ff9800' }}
                    onClick={(e) => { e.stopPropagation(); openGoogleMaps(order.delivery_latitude, order.delivery_longitude); }}
                    startIcon={<DeliveryDiningIcon />}
                  >
                    Navigate to Delivery
                  </Button>
                )}
                {order.status === 'delivered' && (
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ mt: 2, backgroundColor: '#4caf50' }}
                    onClick={(e) => { e.stopPropagation(); alert('Please rate us!'); }}
                  >
                    Rate Us
                  </Button>
                )}
              </CardContent>
            </Card>
          ))}
          <Pagination
            count={Math.ceil(orders.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
          />
        </>
      )}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert onClose={() => setNotification({ ...notification, open: false })} severity="info">
          {notification.message}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <DialogContentText>ID: {selectedOrder.id}</DialogContentText>
              <DialogContentText>Customer: {selectedOrder.customer_name}</DialogContentText>
              <DialogContentText>Order Date: {selectedOrder.order_date}</DialogContentText>
              <DialogContentText>Status: {selectedOrder.status}</DialogContentText>
              <DialogContentText>Total: {selectedOrder.total} LKR</DialogContentText>
              <DialogContentText>Delivery Latitude: {selectedOrder.delivery_latitude}</DialogContentText>
              <DialogContentText>Delivery Longitude: {selectedOrder.delivery_longitude}</DialogContentText>
              <Typography variant="h6" sx={{ mt: 2 }}>Order Items</Typography>
              <List>
                {JSON.parse(selectedOrder.cart_items).map((item, index) => (
                  <ListItem key={index} sx={{ display: 'block', backgroundColor: '#f1f1f1', mb: 1, borderRadius: 2, p: 2 }}>
                    <ListItemText
                      primary={<Typography variant="h6">{item.food}</Typography>}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            Category: {item.category}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Restaurant: {selectedOrder.store_name}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Size: {item.selectedSizes.map(size => size.size).join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Quantity: {item.selectedSizes.map(size => size.quantity).join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Custom Options: {Object.values(item.selectedCustomOptions).flat().join(', ')}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="text.primary">
                            Total Price: {item.totalPrice}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Orders;
