import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, AppBar, Toolbar, Container, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapInstance, setMapInstance] = useState(null);
  const [isActive, setIsActive] = useState(false); 
  const [riderName, setRiderName] = useState(''); // Add state for rider name
  const riderId = sessionStorage.getItem('riderId');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`https://kirupam.shop/admin/admin_api/get_rider_stats.php?rider_id=${riderId}`);
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    const fetchRiderInfo = async () => {
      try {
        const response = await axios.get(`https://kirupam.shop/admin/admin_api/get_rider_info.php?rider_id=${riderId}`);
        setRiderName(response.data.name);
        setIsActive(response.data.active);
      } catch (error) {
        console.error('Error fetching rider info:', error);
      }
    };

    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            setLoading(false);
          },
          (error) => {
            console.error('Error getting location:', error);
            setLoading(false);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setLoading(false);
      }
    };

    fetchStats();
    fetchRiderInfo();
    fetchLocation();
  }, [riderId]);

  useEffect(() => {
    if (location && !mapInstance) {
      const map = L.map('map').setView([location.latitude, location.longitude], 20);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(map);

      const bikeIcon = L.divIcon({
        html: `<div style="font-size: 2rem; color: red;"><i class="fas fa-bicycle"></i></div>`,
        className: 'custom-icon'
      });

      L.marker([location.latitude, location.longitude], { icon: bikeIcon }).addTo(map)
        .bindPopup('Your current location')
        .openPopup();

      setMapInstance(map);
    }
  }, [location, mapInstance]);

  useEffect(() => {
    const updateLocation = async () => {
      if (location) {
        try {
          await axios.post('https://kirupam.shop/admin/admin_api/update_rider_location.php', {
            rider_id: riderId,
            latitude: location.latitude,
            longitude: location.longitude
          });
        } catch (error) {
          console.error('Error updating location:', error);
        }
      }
    };

    const intervalId = setInterval(updateLocation, 10000); // Update location every 10 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [location, riderId]);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.checked;
    setIsActive(newStatus);

    try {
      await axios.post('https://kirupam.shop/admin/admin_api/update_rider_status.php', {
        rider_id: riderId,
        active: newStatus ? 1 : 0
      });
    } catch (error) {
      console.error('Error updating rider status:', error);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Kirupam Deliver Partner</Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Dashboard</Typography>
        <Typography variant="body1">Welcome {riderName} to the Rider Management Dashboard!</Typography>
        <FormControlLabel
          control={<Switch checked={isActive} onChange={handleStatusChange} />}
          label={isActive ? 'Active' : 'Inactive'}
        />

        {stats && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Statistics</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={[
                    { name: 'Total Deliveries', value: stats.totalDeliveries },
                    { name: 'Pending Deliveries', value: stats.pendingDeliveries },
                    { name: 'Completed Deliveries', value: stats.completedDeliveries },
                  ]}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {COLORS.map((color, index) => (
                    <Cell key={`cell-${index}`} fill={color} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        )}

        {location ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Current Location</Typography>
            <Typography variant="body1">Latitude: {location.latitude}</Typography>
            <Typography variant="body1">Longitude: {location.longitude}</Typography>
            <Box id="map" sx={{ height: '400px', marginTop: '1rem', borderRadius: '8px' }}></Box>
          </Box>
        ) : (
          <Typography variant="body1" color="error">Unable to fetch location.</Typography>
        )}
      </Container>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">Developed by Lemda (Pvt) Ltd</Typography>
      </Box>
    </Box>
  );
};

export default Dashboard;
